import { em, hiDPI } from "polished";
import React from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import pattern1_437 from "../../img/pattern/pattern-1-437.png";
import ButtonLink from "../ButtonLink";
import LatestAlert from "../LatestAlert";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Login from "./Login";
import {
  Featured,
  HeroThumb,
  HomeStepText,
  HomeSteps,
  PublishedDate,
  ScoutCaption,
  ScoutFigure,
  ScoutImg,
  ScoutImgWrap,
  ScoutTitle,
} from "./styles";

interface IHomeButtonLink {
  entryActionText: string;
  entryPath: string;
  status: string;
}

const HomeButtonLink: React.FC<IHomeButtonLink> = ({
  entryActionText,
  entryPath,
  status,
}) => {
  // C&P'd from Login.js. Should these constants go somewhere global?
  const app = "pldpl-web";
  const plusers = process.env.REACT_APP_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register?app=${app}&redirect_uri=${redirect}`;
  let actionText = "";
  let path = "";
  if (status === "logged_out") {
    actionText = "Sign Up Now";
    path = register;
  } else if (status === "no_entry") {
    actionText = "Play Now";
    path = "entry/join-create";
  } else {
    actionText = entryActionText;
    path = entryPath;
  }
  return (
    <ButtonLink to={path} variant="light">
      <span>{actionText}</span>
    </ButtonLink>
  );
};

interface IHomeStepImg {
  step: number;
}

const StyledHomeStep = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 32%;
  }
`;

const HomeStepImg = styled.div<IHomeStepImg>`
  position: relative;
  flex: 0 0 180px;
  height: 244px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(../../img/home-step-${(props) => props.step}-180.jpg);

  ${hiDPI(2)} {
    background-size: cover;
    background-image: url(../../img/home-step-${(props) => props.step}-360.jpg);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 1 43%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 47%;
  }
`;

const HomeStepContent = styled.div`
  flex: 1;
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[4]};
  background-repeat: no-repeat;
  background-image: url(${pattern1_437}),
    linear-gradient(to right, ${({ theme }) => theme.colors.lightBlue}, #43a0ff);
  background-position:
    left -57px bottom -225px,
    0 0;
  background-size:
    313px 355px,
    auto;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints[0]}) and (max-width: ${em("1023px")}) {
    flex-basis: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 57%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    flex-basis: 53%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

const HomeStepHeading = styled.h2`
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-size: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    min-height: 46px;
  }
`;

interface IHomeStep {
  children: React.ReactNode;
  headingText: string;
  step: number;
}

const HomeStep: React.FC<IHomeStep> = ({ children, headingText, step }) => (
  <StyledHomeStep>
    <HomeStepImg step={step} />
    <HomeStepContent>
      <HomeStepHeading>{headingText}</HomeStepHeading>
      {children}
    </HomeStepContent>
  </StyledHomeStep>
);

interface IHome {
  status: string;
}

const Home: React.FC<IHome> = ({ status }) => (
  <Wrapper>
    <Main isWide={true}>
      <LatestAlert />
      <Login />
      <HomeSteps>
        <HomeStep headingText="Create or Join a League" step={1}>
          <HomeStepText>
            Take on friends and family or join public leagues against the{" "}
            Fantasy community.
          </HomeStepText>
          <HomeButtonLink
            entryActionText="Play Now"
            entryPath="entry/join-create"
            status={status}
          />
        </HomeStep>
        <HomeStep headingText="Do your Scouting" step={2}>
          <HomeStepText>
            Build a watchlist of player targets in preparation for draft day.
          </HomeStepText>
          <HomeButtonLink
            entryActionText="Draft Room"
            entryPath="draft"
            status={status}
          />
        </HomeStep>
        <HomeStep headingText="Join the Draft" step={3}>
          <HomeStepText>
            Take your turn to pick from the pool and build a 15-man squad unique
            to you.
          </HomeStepText>
          <HomeButtonLink
            entryActionText="The Hub"
            entryPath="hub"
            status={status}
          />
        </HomeStep>
      </HomeSteps>

      <Box m={2}>
        <SubHeading>Latest from The Scout</SubHeading>
      </Box>
      <Box mb={4}>
        <Featured>
          <HeroThumb href="//www.premierleague.com/news/1245430">
            <ScoutFigure>
              <ScoutImgWrap>
                <ScoutImg
                  src="//resources.premierleague.com/photos/2024/09/09/df364edd-51d4-4ae8-83f8-9909754c4255/GettyImages-2168423083.jpg?width=1400&height=800"
                  alt=""
                />
              </ScoutImgWrap>
              <ScoutCaption>
                <ScoutTitle>Time to get your FPL Draft started</ScoutTitle>
                <p>
                  If you haven't tried out Draft before, now is the perfect time
                  to try the exciting way to test your FPL skills
                </p>
                <PublishedDate>8/9/2024</PublishedDate>
              </ScoutCaption>
            </ScoutFigure>
          </HeroThumb>
        </Featured>
      </Box>
    </Main>
  </Wrapper>
);

export default Home;
